<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems">
      <template #action>
        <div class="search mr-2">
          <input
            type="text"
            v-model="form.search"
            class="form-control"
            :placeholder="$t('Search here.....')"
          />
        </div>
        <!-- <div class="sorting">
          <MultiSelectInput
            class="d-flex align-items-center m-0 gap-2"
            :label="'Sort by'"
            :placeholder="''"
          />
        </div> -->
      </template>
    </PageHeader>
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col lg="6">
            <div class="custom-search d-flex justify-content-between mb-1">
              <b-form-group class="m-0">
                <div class="d-flex align-items-center">
                  <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100']"
                    class="ml-1"
                    @input="(value) => onPerPageChange({ pageLength: value })"
                  />
                </div>
              </b-form-group>
              <b-pagination
                :value="1"
                :total-rows="totalRecords"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => onPageChange({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
            <div class="api-keys-table">
              <!-- table -->
              <vue-good-table
                styleClass="vgt-table striped"
                :columns="columns"
                :fixed-header="false"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                  trigger: 'enter',
                }"
                :pagination-options="{
                  enabled: false,
                  perPage: pageLength,
                }"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  class="vgt-center-align vgt-text-disabled"
                  slot="emptystate"
                >
                  {{ $t("No data for table") }}
                </div>
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Name -->
                  <div
                    v-if="props.column.field === 'check'"
                    class="d-flex align-items-center"
                  >
                    <div class="checkbox-group">
                      <input
                        type="checkbox"
                        class="checkbox-input"
                        :id="'check' + props.row.id"
                        :value="props.row.id"
                        @change="toggleSelection(props.row.id)"
                        :checked="selectedRows.includes(props.row.id)"
                      />
                      <label
                        :for="'check' + props.row.id"
                        class="checkbox-label"
                      ></label>
                    </div>
                  </div>
                  <span v-else-if="props.column.field === 'firstName'">
                    {{ props.row.firstName }} {{ props.row.lastName }}
                  </span>
                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-2">
              <button
                @click="deleteUnitUsers"
                :disabled="selectedRows.length == 0"
                class="btn btn-secondary mr-1"
              >
                {{ $t("Delete User") }}
              </button>
              <button @click="storeUnitUser" class="btn btn-primary">
                {{ $t("Add User") }}
              </button>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="e-card">
              <div class="e-card-body">
                <b-row>
                  <b-col lg="6">
                    <TextInput
                      :label="$t('First Name')"
                      :type="'text'"
                      v-model="userUnit.firstName"
                      :placeholder="''"
                    />
                  </b-col>
                  <b-col lg="6">
                    <TextInput
                      :label="$t('Last Name')"
                      :type="'text'"
                      v-model="userUnit.lastName"
                      :placeholder="''"
                    />
                  </b-col>
                  <b-col lg="12">
                    <div class="form-group">
                      <label
                        class="form-label input-label"
                        for="address_for_invoices"
                        >{{ $t("Role") }}</label
                      >
                      <multi-select
                        :multiple="false"
                        label="title"
                        track-by="id"
                        :options="roles"
                        v-model="userUnit.role"
                      />
                    </div>
                  </b-col>
                  <b-col lg="6">
                    <div class="form-group">
                      <label
                        class="form-label input-label"
                        for="address_for_invoices"
                        >{{ $t("Mobile phone number") }}</label
                      >
                      <multi-select
                        :multiple="false"
                        label="dial_code"
                        track-by="dial_code"
                        :options="countries"
                        v-model="userUnit.countryCode"
                      />
                    </div>
                  </b-col>
                  <b-col lg="6">
                    <div class="form-group">
                      <label
                        class="form-label input-label"
                        for="address_for_invoices"
                      >
                      </label>
                      <TextInput
                        :type="'text'"
                        v-model="userUnit.mobileNo"
                        :placeholder="''"
                      />
                    </div>
                  </b-col>
                  <b-col lg="6">
                    <TextInput
                       :label="$t('New Password')"
                      :type="'password'"
                      v-model="form.password"
                    />
                  </b-col>
                  <b-col lg="6">
                    <TextInput
                      :label="$t('Re Enter new Password')"
                      :type="'password'"
                      v-model="form.confirmPassword"
                    />
                  </b-col>

                  <b-col lg="12">
                    <TextInput
                      :label="$t('Email')"
                      :type="'text'"
                      v-model="userUnit.email"
                      :placeholder="''"
                    />
                  </b-col>
                  <b-col lg="12">
                    <div class="form-group">
                      <label
                        class="form-label input-label"
                        for="address_for_invoices"
                        >{{ $t("Organizational Unit") }}</label
                      >
                      <multi-select
                        :multiple="false"
                        label="unit"
                        track-by="id"
                        :options="units"
                        v-model="userUnit.unit"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import NotificationService from "../../services/notification.service";
import MultiSelect from "vue-multiselect";
import countries from "@/assets/data/countries.json";
import TextInput from "@/components/TextInput.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
export default {
  components: {
    PageHeader,
    MultiSelect,
    TextInput,
    MultiSelectInput,
  },
  computed: {
    ...mapGetters("roles", ["roles"]),
    ...mapGetters("units", ["units"]),
    columns() {
      return [
        {
          label: "",
          field: "check",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Name"),
          field: "firstName",
          sortable: true,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Role"),
          field: "roleName",
        },

        // {
        //   tdClass: "vgt-center-align",
        //   thClass: "vgt-center-align white-color",
        //   label: this.$t("City"),
        //   field: "city",
        //   sortable: false,
        // },
        // {
        //   tdClass: "vgt-center-align",
        //   thClass: "vgt-center-align white-color",
        //   label: this.$t("Street"),
        //   field: "street",
        //   sortable: false,
        // },
        // {
        //   tdClass: "vgt-center-align",
        //   thClass: "vgt-center-align white-color",
        //   label: this.$t("Zip"),
        //   field: "zip",
        //   sortable: false,
        // },
        // {
        //   tdClass: "vgt-center-align",
        //   thClass: "vgt-center-align white-color",
        //   label: this.$t("Roles"),
        //   field: "roles",
        //   sortable: false,
        // },
        // {
        //   tdClass: "vgt-center-align",
        //   thClass: "vgt-center-align white-color",
        //   label: this.$t("Action"),
        //   field: "action",
        //   sortable: false,
        // },
      ];
    },
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("User"),
          active: true,
        },
      ];
    },
    optionalItems() {
      return {
        createBtn1: {
          show: false,
          text: this.$t("Create User"),
          icon: "PlusIcon",
          path: "/users/create",
          permission: "user.create",
        },
      };
    },
  },
  data() {
    return {
      selectedRows: [], // Array to hold selected row IDs
      countries,
      totalRecords: 0,
      pageLength: 10,
      page: 1,
      form: {
        sortOrder: "",
        sortBy: "",
        search: "",
      },
      userUnit: {
        firstName: "",
        lastName: "",
        role: null,
        mobileNo: "",
        unit: null,
        countryCode: "",
      },
      rows: [],
      searchTerm: "",
      form: {
        password: "",
        confirmPassword: "",
      },
    };
  },
  watch: {
    "form.search": function (newVal, oldVal) {
      this.loadItems();
    },
  },

  async created() {
    await this.$store.dispatch("roles/list", {
      limit_start: 0,
      limit_count: 100,
    });
    await this.$store.dispatch("units/list");
    this.loadItems();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },
    async storeUnitUser() {
      try {
        if (this.form.password.length || this.form.confirmPassword.length) {
          if (this.form.password !== this.form.confirmPassword) {
            NotificationService.showError(
              "Password and confirm password do not match"
            );
            return;
          }
        }
        if (!this.form.password.length) {
          delete this.form["password"];
          delete this.form["confirmPassword"];
        }
        const payload = {
          ...this.form,
          mail: this.userUnit.email,
          first_name: this.userUnit.firstName,
          last_name: this.userUnit.lastName,
        };

        this.$store.commit("isLoading", true);
        await this.$store.dispatch("users/create", payload).then((res) => {
          this.store(res?.data?.id);
        });
      } catch (e) {}
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    toggleSelection(rowId) {
      const index = this.selectedRows.indexOf(rowId);
      if (index > -1) {
        // If the ID is already selected, remove it
        this.selectedRows.splice(index, 1);
      } else {
        // If the ID is not selected, add it
        this.selectedRows.push(rowId);
      }
    },
    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      let response = await this.$store.dispatch("unitUsers/list", {
        page: this.page,
        ...this.form,
        perPage: this.pageLength,
      });
      this.rows = response?.data?.data;
      this.totalRecords = response?.data?.total;
    },
    async store(userId) {
      await this.$store.dispatch("unitUsers/create", {
        ...this.userUnit,
        userId: userId,
        roleId: this.userUnit?.role?.id ?? "",
        roleName: this.userUnit?.role?.title ?? "",
        unitId: this.userUnit?.unit?.id ?? null,
        countryCode: this.userUnit?.countryCode?.dial_code ?? "",
      });
      this.resetForm();
      this.loadItems();
    },
    resetForm() {
      this.userUnit = {
        firstName: "",
        lastName: "",
        role: null,
        mobileNo: "",
        unit: null,
        countryCode: null,
      };
      this.form = {
        password: "",
        confirmPassword: "",
      };
    },
    async deleteUnitUsers() {
      this.$swal({
        title: this.$t("Do you want to delete these record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("unitUsers/deleteUnitUsers", {
              ids: this.selectedRows,
            })
            .finally(() => {
              this.selectedRows = [];
              this.loadItems();
            });
        }
      });
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
